import { Button, Grid, IconButton, ImageList, ImageListItem, Modal, Backdrop, Box, CircularProgress } from '@mui/material';
import {Storage} from 'aws-amplify';
import { useEffect, useState } from 'react';
import {ImageDefinition} from '../redux/imageSlice';
import Pagination from '@mui/material/Pagination';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Stack from '@mui/material/Stack';
import {StorageImage} from '@aws-amplify/ui-react-storage';
import useMediaQuery from '@mui/material/useMediaQuery';
interface GalleryProps {
    data: Array<ImageDefinition>
}

type GalleryImage = {
    key: string;
    imageUrl: string;
    largeKey: string;
    index: number
}

export default function Gallery(props: GalleryProps){
    const [loaded, setLoaded] = useState(true);
    const [allKeys, setKeys] = useState<Array<ImageDefinition>>(props.data);
    const [images, setImages] = useState<Array<GalleryImage>>([]);
    const [image, setFocusImage] = useState<GalleryImage>({key:"", imageUrl: "", largeKey: "", index: -1});
    const [page, setPage] = useState<number>(0);
    const [arrayIndex, setArrayIndex] =useState<number>(0);
    const [numPerPage, setNumPerPage] = useState<number>(100);
    const [numOfPages, setNumOfPages] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [openModal, setOpen] = useState<boolean>(false);
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50 

    const onTouchStart = (e:any) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e:any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe ) {
            handleNextImage();
        }
        if (isRightSwipe){
            handlePreviousImage();
        }
    }
 
    useEffect(()=>{
        setTotal(allKeys.length);
        setNumOfPages(Math.ceil(total/numPerPage));
    }, [allKeys.length, total, numPerPage]);

    useEffect(() => {
        const data = new Array<GalleryImage>();
        let slice = allKeys.slice(page*numPerPage, page*numPerPage+numPerPage);
        let count = 0;
        slice.forEach(async (img: any) => {
            const image = await Storage.get(img.key);
            
            data.push({imageUrl: image, key: img.key, largeKey: img.key.replace("quartered_images/", "full_images/"), index: count} as GalleryImage);
        });
        setImages(data);
    }, [page, numPerPage, allKeys]);
    
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        
        setPage(value-1);
    };
    const focusImage = async (largeKey:string, key: string) => {
        setLoaded(false);
        const largeUrl = await Storage.get(largeKey);
        const image = {key: key, largeKey: largeKey, imageUrl: largeUrl} as GalleryImage;
        setFocusImage(image);
        setOpen(true);
        // setLoaded(true);
    };
    const handleClose = () => setOpen(false);
    const handleNextImage = async () => {
        setLoaded(false);
        const index  = allKeys.findIndex(a => image.key === a.key);
        if(index !== total-1){
            const nextImage = allKeys[index+1];
            const largeKey = nextImage.key.replace("quartered_images/", "full_images/");
            //const largeUrl = await Storage.get(largeKey);
            const image = {key: nextImage.key, largeKey: largeKey, imageUrl: ""} as GalleryImage;
            setFocusImage(image);
        }else{
            setLoaded(true)
        }
        // setLoaded(true);
    };

    const handlePreviousImage = async () => {
        setLoaded(false);
        const index  = allKeys.findIndex(a => image.key === a.key);
        if(index !== 0){

            const nextImage = allKeys[index-1];
            const largeKey = nextImage.key.replace("quartered_images/", "full_images/");
            ///const largeUrl = await Storage.get(largeKey);
            const image = {key: nextImage.key, largeKey: largeKey, imageUrl: ""} as GalleryImage;
            setFocusImage(image);
        }else{
            setLoaded(true)
        }
        // setLoaded(true);
    };

   return <Stack>
   <Box justifyContent="start" ml='auto' mr='auto'>
     <Pagination count={numOfPages} page={page+1} onChange={handleChange} shape="rounded"/>
   </Box>
    <ImageList cols={4}>
                {images.map((item: GalleryImage) => {
                    return <div style={{cursor: 'pointer'}}  key={item.key} onClick={() => {focusImage(item.largeKey, item.key)}}>
                        <ImageListItem>
                    <img
                        src={`${item.imageUrl}`}
                        alt={item.key}
                        loading="lazy"
                        height={175}
                        style={{maxHeight: 250, maxWidth: 250}}
                    />
                    </ImageListItem>
                    </div>
                    })}
    </ImageList>
    <Box justifyContent="center" m="auto">
        <Pagination count={numOfPages} page={page+1} onChange={handleChange} shape="rounded" />
    </Box>
    <Box justifyContent="center" sx={{backgroundColor: 'white', alignItems: 'center', margin: 'auto'}} alignContent="center">

        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{border: 'none'}}
            >
                <div onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove}>
                    <Grid container  sx={{backgroundColor: 'rgba(0,0,0,.0)', margin: 'auto', border: 'none', }}>
                
                    <Grid container direction="column"
  alignItems="center"
  justifyContent="center">
                        
                        <Grid item xs={1} sx={{flexDirection: 'row-reverse'}}>
                            <Button onClick={handleClose} sx={{color:'white', flex: 1, fontSize: 20}}>Close</Button>
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12}  justifyContent="space-between" alignItems="center" display="flex" style={{height: '80vh'}}>
                            <StorageImage onLoad={()=>{setLoaded(true)}} imgKey={image.largeKey} alt={image.key} accessLevel="public"style={{maxHeight:'80vh', maxWidth:'80vh', width: 'auto', height: 'auto', marginLeft: 'auto', alignItems: 'center', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto'}} />
                            {/* <img 
                            src={image.imageUrl}
                            alt={image.key}
                            loading="lazy"
                            style={{maxHeight:'80vh', maxWidth:'80vh', width: 'auto', height: 'auto', marginLeft: 'auto', alignItems: 'center', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto'}}
                            /> */}
                        
                    </Grid>
                                             
                        <Grid item xs={6} m="auto" flex={1} justifyContent="space-between" pb={2} >
                            <Grid container direction="column"
                                alignItems="center"
                                justifyContent="center">
                            <Grid item xs={2}>
                                
                            <IconButton onClick={handlePreviousImage} >
                                <ArrowBackIosIcon sx={{fontSize: 50, color: 'white'}} />
                            </IconButton>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} m="auto" ml={0} flex={1} justifyContent="space-between" pb={2}>
                            <Grid container direction="column"
                                alignItems="center"
                                justifyContent="center">
                            
                                <Grid item xs={2}>
                                <IconButton onClick={handleNextImage}>
                                    <ArrowForwardIosIcon  sx={{fontSize: 50, color: 'white'}}/>
                                </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    
                </Grid>
        
        
                </div>
        </Modal>
    <Backdrop
    sx={{color: 'white', zIndex:1350,}}
    open={!loaded}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
    </Box>
    
    </Stack>
    
 ;
}