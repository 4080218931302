import './App.css';
import {Amplify} from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import aws_config from './aws-exports';
// import '@aws-amplify/ui-react/styles.css';
import { Box, CircularProgress, Container } from '@mui/material';
import Appbar from './components/Appbar';
import Gallery from './components/Gallery';
import { useEffect } from 'react';
import { getAllImages } from './redux/imageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from './redux/store';
import { SignInFooter } from './components/authentication/SignInFooter';
import { SignInHeader } from './components/authentication/SignInHeader';
import { Header } from './components/authentication/Header';
import { Footer } from './components/authentication/Footer';
// import usePagination from '@mui/material/usePagination';

Amplify.configure(aws_config);
export type ImageDefinition = {
  eTag: string;
  key: string;
  lastModified: string;
  size: number;
}

function App() {
    const dispatch = useDispatch<AppDispatch>();
    // const pagination = usePagination({count: 3});
    const imageSlice = useSelector((state: any) => state);

    useEffect(()=>{
      dispatch(getAllImages());
    }, [dispatch]);
  

  return (
    <div className="App">
      <header className="App-header">
        <Appbar/>
          <Box justifyContent="center" mt={10}>
          <Container style={{marginTop: 6, paddingTop: 6}}>
            {imageSlice?.imageReducer?.value?.loading && <Box><CircularProgress sx={{color:'#fcfce9'}}/></Box>}
            {!imageSlice?.imageReducer?.value?.loading && imageSlice?.imageReducer?.value?.error ? <div>Error: {imageSlice?.imageReducer?.value?.error}</div> : null}
            {!imageSlice?.imageReducer?.value?.loading  &&
              <Gallery data={imageSlice?.imageReducer?.value?.images} />
            }
           
          </Container>
        </Box>
        
      </header>
    </div>
  );
}

export default withAuthenticator(App, {hideSignUp: true, components: {
  Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter
  },
  Footer
}});
