import { createAsyncThunk, createSlice,  } from '@reduxjs/toolkit';
import {Storage} from 'aws-amplify';

export type ImageDefinition = {
    eTag?: string;
    key: string;
    lastModified?: string;
    size?: number;
  }

  async function getAllDetails(){
    let complete = false;
    let nextToken = undefined;
    let data = new Array<ImageDefinition>();
    let directory = "quartered_images";
    while( complete === false ){
        const response: any = await Storage.list(`${directory}/`, {
            level: 'public',
            pageSize: 1000,
            nextToken: nextToken
        });
        response.results.forEach((item: any) => {
            if( item.size !== 0){
                data.push({key: item.key, eTag: item.eTag, size: item.size, lastModified: item.lastModified.toString()} as ImageDefinition)
            }
        });
        if(response.hasNextToken){
            nextToken = await response.nextToken
        }else{
            complete = true;
        }
    }
    data.sort((a, b) => { 
        const sanitisedA = parseInt(a.key.replace(`${directory}/GRETA E JOSH -`,"").replace(".jpg", ""));
        const sanitisedB = parseInt(b.key.replace(`${directory}/GRETA E JOSH -`, "").replace(".jpg", ""));
        if(sanitisedA < sanitisedB) {return -1};
        if(sanitisedA > sanitisedB) {return 1};
        return 0;
    }
    );
    return data;
}

export const getImages = createAsyncThunk('images/getNext', () =>{
    return Storage.list('quartered_images/', {
        level: 'public',
        pageSize: 1000
    }).then((response) => response).catch();
});

export const getAllImages = createAsyncThunk('images/getAll', () =>{
    return getAllDetails().then((result) => result);
});

type ImagesState = {
    images: Array<ImageDefinition>;
    loading: boolean;
    error: string;
    nextToken?: string;
    hasNextPage: boolean
}

const initialImagesState: ImagesState = {
    images: [],
    loading: false,
    error: '',
    nextToken: undefined,
    hasNextPage: true
}


export const images = createSlice({
    name: 'images', 
    initialState: {
        value:initialImagesState
    },
    reducers: {
        
    },
    extraReducers: (builder)=> {
        builder.addCase(getImages.pending, (state) => {
            state.value.loading = true;
        });
        builder.addCase(getImages.fulfilled, (state, action) => {
            
            let images = new Array<ImageDefinition>();
            if(action.payload?.hasNextToken) {
                state.value.nextToken = action.payload.nextToken;
            }else{
                state.value.nextToken = undefined;
                state.value.hasNextPage = false;
            }
            action.payload.results.forEach((img: any) => {
                if(img.size !== 0){
                    images.push({key: img.key, eTag: img.eTag, lastModified: "", size: img.size} as ImageDefinition);
                }
            });
            state.value.images = images;
            state.value.loading = false;
            state.value.error = '';
        });
        builder.addCase(getImages.rejected, (state, action) => {
            state.value.loading = false;
            state.value.images = [];
            state.value.error = action.error.message!;
        });

        builder.addCase(getAllImages.pending, (state) => {
            state.value.loading = true;
        });
        builder.addCase(getAllImages.fulfilled, (state, action) => {
            
            state.value.images = action.payload;
            state.value.loading = false;
            state.value.error = '';
        });
        builder.addCase(getAllImages.rejected, (state, action) => {
            state.value.loading = false;
            state.value.images = [];
            state.value.error = action.error.message!;
        });




    }   
})

export default images.reducer;