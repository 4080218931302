/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:884ecb39-2e2c-4880-8d99-c3b3032633c5",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_iN7TDWQxW",
    "aws_user_pools_web_client_id": "4u43du13c3odggecivri2cj4uv",
    "oauth": {
        "domain": "weddingphotos9c589922-9c589922-dev.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "weddingphotos57480ad24cce4c17bf8e1603bbd2e79d142022-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
