import { AppBar, Toolbar, Button, Typography } from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material';
import {Auth} from 'aws-amplify';
const theme = createTheme(
    {
      typography: {
        button: {textTransform: "none"}
  
    },}
  );
 
export default function Appbar(){
    const handleSignout = () => {
        Auth.signOut();
    }
    return <AppBar
        className="App-Appbar"
        position="fixed"
        sx={{backgroundColor:'#fcfce9'}}
        >
        <Toolbar>
            <img src="/tarlarini.png" width="150px" alt="Villa Tarlarini"  />
            <ThemeProvider theme={theme}>
            <Typography variant="h4" color="black" style={{ flex: 1, fontFamily: "'Great Vibes', cursive" }}>
                Josh & Greta's Wedding
            </Typography>
            </ThemeProvider>
            <Button onClick={handleSignout} variant="outlined" sx={{color:"black", borderColor:'black', "&:hover": { borderColor: 'black'}}}>Sign Out</Button>
        </Toolbar>
    </AppBar>
}